
<template>
    <v-container>
        <AError
        :api="this.api"/>
        <!-- <v-dialog
        v-model="remark">
            <mavon-editor
            v-model = "remarkNotes"
            placeholder = "Taking notes...">
            </mavon-editor>
            <v-toolbar>
                <v-btn
                color="primary">
                    Confirm
                </v-btn>
                <v-btn
                plain
                @click="close">
                    Cancel
                </v-btn>
            </v-toolbar>
        </v-dialog> -->
       
        <div class="d-flex justify-space-between">
            <v-btn
        class="mt-3"
        color="primary"
        @click="closeDetail">
            <v-icon>
                mdi-arrow-left-bold
            </v-icon>
        </v-btn>
        <v-menu offset-y style="padding:0px" v-model="actionDrop">
            <template
            v-slot:activator="{on,attrs }">
                <v-btn
                min-width="150px"
                min-height="50px"
                color="primary"
                v-bind="attrs"
                v-on="on">
                    Actions 
                    <v-icon
                    v-if="actionDrop">
                        mdi-arrow-up
                    </v-icon>
                    <v-icon
                    v-else>
                        mdi-arrow-down
                    </v-icon>
                </v-btn>
            </template>
                <v-list
                >
                    <div
                    v-for="(item,index) in Actions"
                    :key="index">
                        <v-list-item
                        v-if="item.rolesAllowed.includes($store.getters.getRole)"
                        @click="actions(item.action)">
                            <v-icon>
                                mdi-newspaper
                            </v-icon>{{ item.name }}
                        </v-list-item>
                    </div>
                </v-list>
       </v-menu>    
        </div>
        <div class="mt-2">
        <v-toolbar
        color="secondary">
            <div
            class="white--text text-h5">
            <v-icon
            color="white">
                mdi-domain
            </v-icon>
            Company
            </div>
        </v-toolbar>

        <v-card
        v-if="this.company"
        class="pa-5">
        <v-row no-gutters>
            <v-col
            class="pr-3"
            cols="12"
            sm="6">
                <div
                class="mx-2">
                    
                    <div
                    class="d-flex justify-space-between">
                        <p class="text-h7">
                            Name :  
                        </p>
                        <p class="text-h7">
                        {{ company.name }} 
                        </p>
                    </div>
                    <div
                    class="d-flex justify-space-between">
                        <p class="text-h7">
                            SSM :  
                        </p>
                        <p class="text-h7">
                        {{ (company.ssm_no)?company.ssm_no:'-' }} 
                        </p>
                    </div>
                    <div
                    class="d-flex justify-space-between">
                        <p class="text-h7">
                            Address :
                        </p>
                        <p class="text-h7">
                            {{ (company.address)?company.address:'-' }}
                        </p>
                    </div>
                    <div
                    class="d-flex justify-space-between">
                        <p class="text-h7">
                            Industry :
                        </p>
                        <p class="text-h7">
                            {{ (company.industry)?company.industry:'-' }}
                        </p>
                    </div>
                    <div
                    class="d-flex justify-space-between">
                        <p class="text-h7">
                            Status :
                        </p>
                        <p class="text-h7 font-weight-bold">
                            {{ company.status }}
                        </p>
                    </div>
                    <!-- <div
                    class="">
                        <p class="text-h7">Followup status:</p>
                        <v-icon
                        v-if="company.followup==true"
                        color="green">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon
                        v-else>
                            mdi-checkbox-marked-circle
                        </v-icon>
                    </div> -->
                    <div
                    class="d-flex justify-space-between">
                        <div class="text-h5 mb-3">
                            Payment method
                        </div>
                    </div>
                    <div class="d-flex justify-start">
                        <p class="text-h7 font-weight-bold">
                            Credit card
                        </p>
                        
                    </div>
                    <div>
                        <div class="d-flex justify-space-between">
                            <p class="text-h7">
                                Brand :
                            </p>
                            <p class="text-h7">
                                {{ (company.card_brand)?company.card_brand:'-' }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between">
                            <p class="text-h7">
                                Last 4 digits :
                            </p>
                            <p class="text-h7">
                                {{ (company.card_last_four)?company.card_last_four:'-' }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between">
                            <p class="text-h7">
                                Expired at :
                            </p>
                            <p class="text-h7">
                                {{ (company.expired)?company.expired:'-' }}
                            </p>
                        </div>
                    </div>
                    <!-- <div v-if = "company.payment_method=='Online banking'">
                        <div class="d-flex justify-space-between">
                            <p>
                                Type :
                            </p>
                            <p>
                                {{ (company.bank_type)?company.bank_type:null }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between">
                            <p>
                                 Account number :
                            </p>
                            <p>
                                {{ (company.bank_account_number)?company.bank_account_number:null }}
                            </p>
                        </div>
                        
                    </div> -->
                    <!-- <div class="d-flex justify-end">
                        <v-btn color="primary"
                        @click="readAllPaymentUpdate">
                            Show all payment method
                        </v-btn>
                    </div> -->
                    
                    
                    <!-- <div class="d-flex justify-end my-2">
                        <v-btn
                        @click="scrollDown"
                        color="primary">
                            Show company log
                        </v-btn>
                    </div> -->
                   
                    <!-- <div
                    class="d-flex justify-space-between">
                        <p class="text-h7">
                            Credit card :
                        </p>
                        <p class="text-h7">
                            {{ company.creditCard.brand }}
                        </p>
                    </div> -->
                    
                </div>
            </v-col>
            <v-col
            
            class="pl-3"
            cols="12"
            sm="6">
            <div class="text-h5 mb-3">
               Last subscription
            </div>
            <!-- <v-skeleton-loader
            class="ma-4"
            ref="skeleton"
            type="card-avatar"
            v-if="this.isLoadingSubscription">
            </v-skeleton-loader> -->
                <div class="mx-2"
                v-if="this.subscription">
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">
                            Name :  
                        </p>
                        <p class="text-h7">
                            {{ subscription.name }} {{ subscription.name2 }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">
                            Price :
                        </p>
                        <p class="text-h7 text-decoration-line-through"
                        v-if="(subscription.coupon)">
                            RM {{ subscription.price }}
                            <!-- <p v-if="subscription.price=='0'">
                                * Payment handled by admin
                            </p> -->
                        </p>
                        <p class="text-h7"
                        v-else>
                            RM {{ subscription.price }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between"
                    v-if="subscription.coupon">
                        <p class="text-h7">
                            Discount : 
                        </p>
                        <p class="text-h7">
                            RM {{ (subscription.price - subscription.amount_paid) }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between"
                    v-if="subscription.coupon">
                        <p class="text-h7">
                            Total price : 
                        </p>
                        <p class="text-h7 font-weight-bold">
                            RM {{ subscription.amount_paid }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">
                            Coupon : 
                        </p>
                        <p class="text-h7">
                            {{ (subscription.coupon)?subscription.coupon:"Not applicable" }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">
                            Subscription date :
                        </p>
                        <p class="text-h7">
                            {{ subscription.created_at.split('T')[0]}}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">
                            Next renewal date :
                        </p>
                        <p class="text-h7">
                            {{ subscription.renewal_at.split(' ')[0] }}
                        </p>
                    </div>
                    <div class="d-flex justify-end">
                        <v-btn
                        class="text-h10"
                        color="primary"
                        @click="readAllSubscription">
                            Show all 
                        </v-btn>
                    </div>

                    <div 
                    class="mt-3">
                        <hr/>
                        <div class="text-h5 my-2">
                            Latest renewal 
                        </div>
                        <div
                        v-if="latestRenewalForm">
                            <div class="d-flex justify-space-between">
                                <p>Report date :</p>
                                <p>
                                    {{ latestRenewalForm.created_at.split("T")[0] }}
                                </p>     
                            </div>                   
                            <div>
                                <div class="d-flex justify-space-between mb-2">
                                    <div>
                                        Previous renewal status
                                    </div>
                                    <div class="d-flex flex-row justify-end">
                                        
                                        <div v-for="(item,index) in renewalStatus"
                                        :key="index">
                                        <div v-if="index<5">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on,attrs}">
                                                    <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="item.status=='Confirm'"
                                                    color="green">
                                                        mdi-checkbox-marked
                                                    </v-icon>
                                                    <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="item.status=='Pending'"
                                                    color="yellow">
                                                        mdi-checkbox-marked
                                                    </v-icon>
                                                    <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="item.status=='Unreachable'"
                                                    color="grey">
                                                        mdi-checkbox-marked
                                                    </v-icon>
                                                    <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="item.status=='Rejected'"
                                                    color="red">
                                                        mdi-checkbox-marked
                                                    </v-icon>
                                                </template>
                                                <span>
                                                    <div>
                                                        {{ item.created_at }}
                                                    </div>
                                                    <div>
                                                        {{ item.status }}
                                                    </div>
                                                </span>
                                            </v-tooltip>

                                        </div>
                                            <!-- <v-checkbox
                                            color="success"
                                            v-model="isChecked"
                                            v-if="item=='Confirm'">
                                            </v-checkbox>
                                            <v-checkbox
                                            class="yellow"
                                            v-modle="isChecked"
                                            v-if="item=='Pending'">
                                            </v-checkbox> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between mb-2">
                                    <div>
                                        Total renewal update (2023)
                                    </div>
                                    <div>
                                        {{ renewalStatus.length }}
                                    </div>
                                </div>
                            </div>
                            <div
                            class="d-flex justify-space-between">
                                <p class="text-h7">
                                    Status :
                                </p>
                                <p class="text-h7 font-weight-bold">
                                    <v-chip
                                    class="white--text"
                                    color="green"
                                    v-if="latestRenewalForm.renewal_status=='Confirm'">
                                        {{ latestRenewalForm.renewal_status }}
                                    </v-chip>
                                    <v-chip
                                    class="white--text"
                                    color="red"
                                    v-if="latestRenewalForm.renewal_status=='Rejected'">
                                        {{ latestRenewalForm.renewal_status }}
                                    </v-chip>
                                    <v-chip
                                    color="grey"
                                    v-if="latestRenewalForm.renewal_status=='Unreachable'">
                                        {{ latestRenewalForm.renewal_status }}
                                    </v-chip>
                                    <v-chip
                                    class="black--text"
                                    color="yellow"
                                    v-if="latestRenewalForm.renewal_status=='Pending'">
                                        {{ latestRenewalForm.renewal_status }}
                                    </v-chip>
                                </p>
                            </div>
                            <div class="d-flex justify-space-between"
                            v-if="latestRenewalForm.renewal_status == 'Confirm'">
                                <p>
                                    Client promised payment date : 
                                </p>
                                <p>
                                    {{ latestRenewalForm.confirm_at.split("T")[0] }}
                                </p>     
                            </div>
                            <!-- <div class="d-flex justify-space-between">
                                <p>
                                    Year of next renewal :
                                </p>
                                <p
                                v-if="latestRenewalForm.renewal_status == 'Confirm'">
                                    {{ parseInt(latestRenewalForm.confirm_at.split("T")[0].split("-")[0])+1 }}
                                </p>
                                <p
                                v-else>
                                    {{ parseInt(latestRenewalForm.created_at.split("T")[0].split("-")[0])+1 }}
                                </p>     
                            </div> -->
                            <p>
                                Remark :
                            </p>
                            <mavon-editor
                            style="z-index:0;min-height:100px;height:auto;width:100%"
                            height="auto"
                            width="auto"
                            defaultOpen="preview" 
                            :toolbarsFlag="false" 
                            :subfield="false"
                            language="en"
                            v-model="latestRenewalForm.remark"
                            v-if="latestRenewalForm.remark != null"
                            :editable="false">
                            </mavon-editor>
                            <mavon-editor
                            style="z-index:0;min-height:100px;height:auto;width:100%"
                            height="auto"
                            width="auto"
                            defaultOpen="preview" 
                            :toolbarsFlag="false" 
                            :subfield="false"
                            language="en"
                            v-model="isRemarkNull"
                            v-else
                            :editable="false">
                            </mavon-editor>
                            
                            <div class="d-flex justify-end mt-2">
                                <v-btn 
                                @click="readAllRenewalUpdate"
                                color="primary">
                                    Show all 
                                </v-btn>
                            </div>
                        </div>
                        <div
                        v-else>
                            <span>No renewal status update</span>
                        </div>
                    </div>
                
                </div>
            </v-col>
        </v-row>
        <hr class="mt-6">

        <v-row>
            <v-col
            cols="12"
            sm="12"
            class="px-7">
            <!-- <v-card class="mt-3"> -->

            
        <v-tabs color="secondary">
            <v-tab>
                User
            </v-tab>
            <v-tab>
                PIC
            </v-tab>
            <v-tab-item>
                <!-- <v-skeleton-loader
                class="ma-4"
                ref="skeleton"
                type="card-avatar"
                v-if="this.isLoadingUser">
                </v-skeleton-loader> -->
                <v-card
                height="auto"
                width="auto"
                elevation-6>
                    <v-row>
                      
                        <v-col 
                        cols="12"
                        sm="6"
                        v-for="(item,index) in client"
                        :key="index">
                            <v-card class="pa-2 ma-2">
                                <div class="mx-2">
                                    <div class="d-flex justify-space-between">
                                        <p class="text-h7">
                                            Name :  
                                        </p>
                                        <p class="text-h7">
                                            {{ item.user_name }}
                                        </p>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <p class="text-h7">
                                            Position :
                                        </p>
                                        <p class="text-h7">
                                            {{ (item.position)?item.position:"-"}}
                                        </p>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <p class="text-h7">
                                            Phone no : 
                                        </p>
                                        <p class="text-h7">
                                            {{ item.mobile }}
                                        </p>
            
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <p class="text-h7">
                                            Email :
                                        </p>
                                        <p class="text-h7">
                                            {{ item.email}}
                                        </p>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <p class="text-h7">
                                            Gender :
                                        </p>
                                        <p class="text-h7">
                                            {{ (item.gender)?item.gender:"-"}}
                                        </p>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card>
                    <v-row
                    v-if="(staff!=null)">
                        <v-col
                        cols="12"
                        sm="4"
                        v-for="(item,index) in staff"
                        :key="index">
                            <v-card class="pa-2">
                            <div class="d-flex justify-space-between">
                                <p class="text-h7">
                                    Name :  
                                </p>
                                <p class="text-h7">
                                    {{ item.name }}
                                </p>
                            </div>
                            
                            
                            <!-- <v-toolbar
                                color="primary">
                                <div class="text-h4 ma-4">
                                    {{ item.role }}
                                </div>
                            -->
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Phone no : 
                                    </p>
                                    <p class="text-h7">
                                        {{ item.mobile }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Email :
                                    </p>
                                    <p class="text-h7">
                                        {{ item.email}}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Role :
                                    </p>
                                    <p class="text-h7">
                                        {{ item.role}}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-h7">
                                        Gender :
                                    </p>
                                    <p class="text-h7">
                                        {{ item.gender}}
                                    </p>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row
                    v-else>
                        <v-col
                        class="mt-2">
                        <AWarning
                        message="No Person-in-charge assigned"/>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            </v-tabs>
        <!-- </v-card> -->
            </v-col>
        </v-row>
        </v-card>
        <div
        class="d-flex justify-end my-5">
       
        </div>
        <!-- <v-container> -->
          
            <v-toolbar
            color="secondary white--text">
            Log
            </v-toolbar>
            <!-- <v-skeleton-loader
            class="ma-4"
            ref="skeleton"
            type="card-avatar"
            v-if="this.isLoadingLog">
            </v-skeleton-loader> -->
            <div>
            
                <v-card 
                v-if="logs!=null">
                    <div
                    class="pa-3"
                    v-for="(item,index) in logs"
                    :key = index>
                        <v-card >
                            <div
                            class="d-flex justify-space-between pa-3 ">
                            <div class="d-flex">
                                {{ item.title }}
                            </div>
                            <div class="d-flex">
                                <!-- {{ item.created_at.split("T")[0]}} {{ item.created_at.split("T")[1].split(".")[0] }}
                                  -->
                                  <!-- {{ item.created_at.split(" ")[0] }} {{ item.created_at.split(" ")[1] }} -->
                            </div>
                            </div>
                            <v-card-text class="d-flex justify-start">
                                {{ item.description }}
                            </v-card-text>
                        </v-card>
                        <div
                        class="d-flex justify-center py-4">
                        </div>
                    </div>
                </v-card>
                <v-card
                v-else>
                    <div class="pa-3">
                        No logs recorded
                    </div>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>
import mavonEditor from 'mavon-editor';
export default{
    components:{
        'mavon-editor':mavonEditor.mavonEditor,
    },
    data:()=>({
        renewalStatus:[
            {
                status:'Confirm',
                created_at:'12/9/2023',

            },
            {
                status:'Unreachable',
                created_at:'10/9/2023',

            },
            {
                status:'Confirm',
                created_at:'8/9/2023',

            },
            {
                status:'Rejected',
                created_at:'1/9/2023',

            },
            {
                status:'Pending',
                created_at:'29/8/2023',

            },
            {
                status:'Pending',
                created_at:'29/8/2023',

            }
            
        ],
        isChecked:true,
        isLoadingSubscription:true,
        isLoadingUser:true,
        isLoadingLog:true,
        isRemarkNull:"No remark recorded",
        remark:"remark section",
        remarkNotes:"Remark section",
        actionDrop:false,
        role : "vision_admin",
        api:{
            isLoading:false,
            isError:false,
            isSuccesful:false,
            error:null,
            url:null,
        },
        company:{
            name:"YYC GST CONSULTANT",
            ssm_no:'232323434',
            address:'Kuala Lumpur',
            industry:'Accounting services',
            status:'Valid',
            card_brand:'Visa',
            card_last_four:'3344',
            expired:'12/9/2023'

        },
        latestRenewalForm:{
            created_at:'21/2/2023',
            renewal_status:'Pending',
            confirm_at:'12/2/2023',
            remarK:'this is mockup'

        },
        // latestPaymentUpdateForm:null,
        
        subscription:{
            name:'Masterclass',
            name2:'Promotion plan',
            coupon:null,
            price:'2038',
            amount_paid:'2038',
            created_at:'21/2/2019',
            renewal_at:'23/2/2032'
        },
        renewalDate:null,
        
        client:[
            {
                user_name:'irfan hafiz',
                position:'General Employee',
                mobile:'011-69912862',
                email:'irfanhafiz329@gmail.com',
                gender:'Male',
            },
            {
                user_name:'Steve Heng Nam',
                position:'General Manager',
                mobile:'011-69912862',
                email:'NgHenName@gmail.com',
                gender:'Male',  
            }
        ],
       
        staff:[
            {
                name:'AAAAAA',
                mobile:'0123-21121',
                email:'aaaaa@gmail.com',
                Role:'Salesperson',
                Gender:'Male',
            }
        ],
        logs:[{
            title:'Mockup two',
            created_at:'12/99/2022',
            description:'This is just a mockup status to demonstate'
        },
        {
           title:'Mockup three',
           created_at:'12/22/2023',
           description:'AAAAAssssadsadjasjdk  djsdksdakdasdd Loreim Palsu aal siu sss' 
        }
    ],
        Actions:{
            1:{
                name:'Update Renewal status',
                action:'Renewal',
                rolesAllowed:Array.from(["salesperson","vision_admin"])
            },
            // 2:{
            //     name:'Payment method update',
            //     action:'Payment',
            //     rolesAllowed:Array.from(["vision_admin"])
            // },
            // 3:{
            //     name:'Follow-up',
            //     action:'NameOfFunction',
            //     rolesAllowed:Array.from(["salesperson"])
            // }
        }
    }),
    created()
    {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => 
        {
            
            if(resp.status == "success")
            {
                if(this.api.url == process.env.VUE_APP_SERVER_API+'/company/'+this.company.company_id+"?user=true&staff=true")
                {
                    this.client = resp.data.users;
                    this.staff = (resp.data.pic.length!=0)?resp.data.pic:null;
                }
                if(this.api.url == process.env.VUE_APP_SERVER_API+"/subscription/"+this.company.company_id+'/latest' )
                {
                    this.subscription = resp.data;
                }
                if(this.api.url == process.env.VUE_APP_SERVER_API+"/log/"+this.company.company_id)
                {
                    this.logs = (resp.data.length!=0)?resp.data:null
                }
                return 1;
            }
        }
    },
    mounted(){
        // this.fetch();
    },
    methods:{
        fetch(){    
            this.role = this.$store.getters.getRole;
            this.api.isLoading = true;
            this.company = this.$store.getters.getCompany;
            
            this.latestPaymentUpdateForm = (this.company.vision_latest_payment_update_form)?JSON.parse(this.company.vision_latest_payment_update_form):null;
            this.latestRenewalForm = (this.company.vision_latest_renewal_form)?JSON.parse(this.company.vision_latest_renewal_form):null;
           
            // this.fetchSubscription().then(()=>{
            //     this.fetchUser();
            //     this.api.isLoading =false;
            //     this.isLoadingSubscription = false;
            // });
        },
        fetchSubscription()
        {
            // this.api.method = "get";
            // this.api.url  = process.env.VUE_APP_SERVER_API+"/subscription/"+this.company.company_id+'/latest';
            // return this.$api.fetch(this.api);
        },
        fetchUser()
        {
            // this.api.method = "get";
            // this.api.url = process.env.VUE_APP_SERVER_API+'/company/'+this.company.company_id+"?user=true&staff=true";
            // this.$api.fetch(this.api).then(()=>{
            //     this.isLoadingUser = false;
            //     this.fetchLog();
            // });
        },
        fetchLog()
        {
            // this.api.method = "get";
            // this.api.url = process.env.VUE_APP_SERVER_API+'/log/'+this.company.company_id;
            // this.$api.fetch(this.api).then(()=>{
            //     this.isLoadingLog = false;
            // })
        },
        scrollDown(){
            setTimeout(this.goDown,200);
        },
        goDown(){
            window.scrollTo({
                left:0,
                top:document.body.scrollHeight,
                behavior:'smooth',
            });
        },
        displayAction()
        {
            this.actionDrop = !this.actionDrop;
        },
        actions(actionName){
            this.$store.commit("updateAction",actionName);
            // if(actionName == "remark"){
            //     this.remark = true;
            // }
            this.$router.push({name:'CompaniesForm'})
        },
        close()
        {
            this.remark = false;
        },
        closeDetail()
        {
            this.$store.dispatch('clearCompany') 
            this.$router.push({name:'Companies'});
        },
        readAllSubscription(){
            this.$router.push({name:'CompaniesSubscription',params:{'companyId':this.company.company_id}});
        },
        readAllRenewalUpdate()
        {
            this.$router.push({name:'CompaniesRenewalUpdate',params:{'companyId':this.company.company_id}});
        },
        readAllPaymentUpdate()
        {
            this.$router.push({name:'CompaniesPaymentUpdate',params:{
                'companyId':this.company.company_id
            }});
        }

    }
}
</script>
